/**
 * Texts for Training page
 */
export const trainingPageText = {
    title: 'Training - CamAPS FX',
    titleMeta: 'Wij bieden gratis een online-training aan voor CamAPS FX-gebruikers',
    descriptionMeta: 'Cambridge Diabetes Education Programme biedt een gratis gecertificeerde online-training aan voor personen met diabetes type 1, ouders en voogden, schoolpersoneel en diabetesbehandelaren.',
    keywords:
        'camaps training, camaps fx training,  ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'nl_NL',

    trainingHeader: 'Gecertificeerde training',
    trainingHeader_2: 'Voor diabetesbehandelaren',
    trainingHeader_3: 'Gebruikerstraining',
    trainingHeaderText: 'GRATIS online CamAPS FX-training, beschikbaar gesteld door het Cambridge Diabetes Education Programme (CDEP, het opleidingsprogramma van Cambridge op het gebied van diabetes).',
    websiteLink: 'https://www.camdiabtraining.com/account-sign-in.html',
    websiteLinkHeading: 'CDEP-training',
    getTrained: 'Volg nu een training!',

    trainingHeaderIat: "CamAPS FX-training",
    trainingHeaderTextIat: "Voltooi de essentiële in-app training voordat je de CamAPS FX-app gebruikt.",
    trainingSubHeaderOneIat: "Aan de slag",
    trainingSubTextOneIat: "Download de CamAPS FX-app. <br>Maak een account aan om met de training te beginnen.",
    trainingSubHeaderTwoIat: "Hulp nodig bij het installeren van de app?",
    trainingParaTwoStartIat: "Bezoek onze",
    trainingParaTwoEndIat: "sectie voor begeleiding.",
    websiteLinkHeadingSupportIat: "Ondersteuning",
    trainingSubHeaderThreeIat: "Aanvullende bronnen",
    animationsYpsoPumpText: "Animaties",
    animationsAndText: "en",
    animationsDanaText: "Animaties (Dana-pomp)",
    animationsFurtherText: "Leer via stapsgewijze visuals.",
    webinarsText: "Webinars",
    webinarsFurtherText: "Bekijk sessies geleid door experts over het gebruik van de app.",
    websiteAnimationsYpsoPumpLink: "https://www.camdiabtraining.com/view/27/mylife-YpsoPump-users.htm",
    websiteAnimationsDanaLink: "https://www.camdiabtraining.com/view/25/Dana-insulin-pump-users.htm",
    websiteWebinarsLink: "https://www.camdiabtraining.com/view/20/Webinars.htm"

};
