/**
 * Texts for Training page
 */
export const trainingPageText = {
    title: 'Školení – CamAPS FX',
    titleMeta: 'Uživatelům aplikace CamAPS FX nabízíme zdarma školení',
    descriptionMeta: 'Cambridge Diabetes Education Programme pořádá bezplatné certifikované online školení pro osoby s diabetem 1. typu, rodiče a opatrovatele a školní a zdravotnické pracovníky.',
    keywords:
        'školení camps, školení camps fx, ' ,
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'cs_cz',

    trainingHeader: 'Certifikované školení',
    trainingHeader_2: 'Pro zdravotnické pracovníky',
    trainingHeader_3: 'Školení uživatelů',
    trainingHeaderText: 'BEZPLATNÉ online školení CamAPS FX na platformě Cambridge Diabetes Education Programme (CDEP).',
    websiteLink: 'https://www.camdiabtraining.com/account-sign-in.html',
    websiteLinkHeading: 'Školení CDEP',
    getTrained: 'Přihlásit se na školení',

    trainingHeaderIat: "Školení CamAPS FX",
    trainingHeaderTextIat: "Dokončete základní školení v aplikaci před použitím aplikace CamAPS FX.",
    trainingSubHeaderOneIat: "Začínáme",
    trainingSubTextOneIat: "Stáhněte si aplikaci CamAPS FX. <br>Vytvořte si účet a začněte školení.",
    trainingSubHeaderTwoIat: "Potřebujete pomoc s instalací aplikace?",
    trainingParaTwoStartIat: "Navštivte naši",
    trainingParaTwoEndIat: "sekci s pokyny.",
    websiteLinkHeadingSupportIat: "Podpora",
    trainingSubHeaderThreeIat: "Další zdroje",
    animationsYpsoPumpText: "Animace",
    animationsAndText: "a",
    animationsDanaText: "Animace (pumpa Dana)",
    animationsFurtherText: "Naučte se díky krok za krokem vizuálním ukázkám.",
    webinarsText: "Webináře",
    webinarsFurtherText: "Sledujte odborně vedené sezení o používání aplikace.",
    websiteAnimationsYpsoPumpLink: "https://www.camdiabtraining.com/view/27/mylife-YpsoPump-users.htm",
    websiteAnimationsDanaLink: "https://www.camdiabtraining.com/view/25/Dana-insulin-pump-users.htm",
    websiteWebinarsLink: "https://www.camdiabtraining.com/view/20/Webinars.htm"

};
