/**
 * Texts for header
 */
export const headerText = {
    cookiesIntroText: 'Wir setzen Cookies lediglich zur Nachverfolgung von Websitebesuchen ein. Wir speichern keine persönlichen Daten.',
    cookiesAccept: 'Cookies akzeptieren',
    cookiesDeny: 'Cookies ablehnen',
    tabFAQs: 'FAQs',
    tabSupport: 'Support',
    tabHistory: 'Geschichte',
    tabReviews: 'Bewertungen',
    // tabTraining: 'Training',
    // tabHcpTraining: '',
    tabTraining: 'Benutzertraining',
    tabHcpTraining: 'Gesundheitspersonaltraining',
    // tabOrders: 'Bestellungen',
    tabOrders: '',
    tabNotifications: 'Benachrichtigungen',
};

