/**
 * Texts for Notifications page
 */
export const notificationsPageText = {
    title: 'Notifications récentes dans l\'application',
    titleMeta: 'Affichage des notifications récentes dans l\'application CamAPS FX',
    descriptionMeta: 'Voici le texte des récentes notifications intégrées à l\'application concernant les mises à jour de l\'application, les avis de sécurité, les mises à jour du manuel d\'utilisation',
    keywords: 'Notifications' ,
    localeMeta: 'fr_fr',

    heading: 'Notifications récentes dans l\'application',
    subheading: '',
	
	header_41:'',
    body_41:'',

    header_40:'',
    body_40:'',

    header_39:'',
    body_39:'',

    header_38:'',
    body_38:'',

    header_37:'',
    body_37:'',

    header_36:'',
    body_36:'',

    header_35:'',
    body_35:'',

    header_34:'',
    body_34:'',

    header_33:'',
    body_33:'',

    header_32:'',
    body_32:'',

    header_31:'',
    body_31:'',

    header_30:'',
    body_30:'',

    header_29:'',
    body_29:'',

    header_28:'',
    body_28:'',

    header_27:'',
    body_27:'',

    header_26:'',
    body_26:'',

    header_25:'',
    body_25:'',

    header_24:'',
    body_24:'',

    header_23:'',
    body_23:'',

    header_22:'',
    body_22:'',

    header_21:'',
    body_21:'',

    header_20:'',
    body_20:'',

    header_19:'',
    body_19:'',

    header_18:'',
    body_18:'',

    header_17:'',
    body_17:'',

    header_16:'',
    body_16:'',

    header_15:'',
    body_15:'',

    header_14:'',
    body_14:'',

    header_13:'',
    body_13:'',
    body_13_link_text:'',
    body_13_end: '',
    body_13_link:'',

    header_12:'',
    body_12:'',

    header_11:'',
    body_11:'',

    header_10:'',
    body_10:'',

    header_9:'',
    body_9:'',

    header_8:'',
    body_8:'',

    header_7:'',
    body_7:'',

    header_6:'',
    body_6:'',

    header_5:'',
    body_5:'',

    header_4:'',
    body_4:'',

    header_3:'',
    body_3:'',

    header_2:'',
    body_2:'',

    header_1:'',
    body_1:'',

};
