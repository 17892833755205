/**
 * Texts for Notifications page
 */
export const notificationsPageText = {
    title: 'Notifiche recenti in-app',
    titleMeta: 'Visualizzazione delle notifiche in-app recenti CamAPS FX',
    descriptionMeta: 'Ecco il testo delle recenti notifiche in-app relative ad aggiornamenti delle app, avvisi di sicurezza, aggiornamenti del manuale dell\'utente',
    keywords: 'Notifiche' ,
    localeMeta: 'it',

    heading: 'Notifiche recenti in-app',
    subheading: '',
	
	header_41:'',
    body_41:'',

    header_40:'Nuovo aggiornamento (18/03/2025)',
    body_40:'Caro utente di mylife CamAPS FX, Una nuova versione dell\'app sarà pronta nelle prossime 48 ore. Per scegliere il momento preferito per l\'aggiornamento, disattiva l\'aggiornamento automatico per CamAPS FX nel Google Play Store. Se hai domande, contatta il servizio clienti di Ypsomed.',

    header_39:'',
    body_39:'',

    header_38:'',
    body_38:'',

    header_37:'Interruzione della vecchia versione dell\'app (solo Ypsopump)',
    body_37:'La versione attuale smetterà di funzionare il 7 febbraio 2025. Si prega di aggiornare l\'applicazione all\'ultima versione disponibile su Google Play.',

    header_36:'Una nuova versione dell\'app 06/01/2025',
    body_36:'Caro utente di mylife CamAPS FX, Una nuova versione dell\'app sarà pronta nelle prossime 24 ore. Per scegliere il momento preferito per l\'aggiornamento, disattiva l\'aggiornamento automatico per CamAPS FX nel Google Play Store. Se hai domande, contatta il servizio clienti di Ypsomed.',	

	header_35:'OS 8 & 9 29/12/2024',
    body_35:'Per motivi di cyber security, dal 1º gennaio 2025, mylife CamAPS FX sarà supportato solo su telefoni in lista di compatibilità del sensore e con sistemi operativi Android 10 e superiori. Gli utenti dei sistemi operativi inferiori a OS 10 non potranno più aggiornare o reinstallare mylife CamAPS FX dopo questa data. Promemoria, il sistema non è ancora compatibile con OS15.',
	
	header_34:'',
    body_34:'',
	
	header_33:'Avviso di Manutenzione Programmata',
    body_33:'A causa della manutenzione programmata del servizio cloud di CamAPS FX, la funzionalità Companion sarà non disponibile il 7 dicembre dalle 10:00 GMT per circa tre ore. Ci scusiamo per gli eventuali disagi che ciò potrebbe causare.',
	
	header_32:'Aggiornamento del manuale utente 28/11/2024',
    body_32:'È disponibile una versione aggiornata del manuale d\'uso.',
	
	header_31:'Una nuova versione dell\'app 17/11/2024',
    body_31:'Caro utente di mylife CamAPS FX, Una nuova versione dell\'app sarà pronta nelle prossime 24 ore. Per scegliere il momento preferito per l\'aggiornamento, disattiva l\'aggiornamento automatico per CamAPS FX nel Google Play Store. Se hai domande, contatta il servizio clienti di Ypsomed.',
	
	header_30:'OS 8 & 9 11/10/2024',
    body_30:'Per motivi di cyber security, dal 1º gennaio 2025, mylife CamAPS FX sarà supportato solo su telefoni in lista di compatibilità del sensore e con sistemi operativi Android 10 e superiori. Gli utenti dei sistemi operativi inferiori a OS 10 non potranno più aggiornare o reinstallare mylife CamAPS FX dopo questa data',
	
	header_29:'Una nuova versione dell\'app 09/10/2024',
    body_29:'Caro utente di mylife CamAPS FX, Una nuova versione dell\'app sarà pronta nelle prossime 48 ore. Per scegliere il momento preferito per l\'aggiornamento, disattiva l\'aggiornamento automatico per CamAPS FX nel Google Play Store. Se hai domande, contatta il servizio clienti di Ypsomed.',
	
	header_28:'OS 8 & 9 08/07/2024',
    body_28:'Per motivi di cyber security, dal 1º gennaio 2025, mylife CamAPS FX sarà supportato solo su telefoni in lista di compatibilità del sensore e con sistemi operativi Android 10 e superiori. Gli utenti dei sistemi operativi inferiori a OS 10 non potranno più aggiornare o reinstallare mylife CamAPS FX dopo questa data',
	
	header_27:'',
    body_27:'',

	header_26:'Aggiornamento del manuale utente 11/05/2024',
    body_26:'È disponibile una versione aggiornata del manuale d\'uso.',
	
	header_25:'Una nuova versione dell\'app 05/05/2024',
    body_25:'Caro utente di mylife CamAPS FX, Una nuova versione dell\'app sarà pronta nelle prossime 24 ore. Per scegliere il momento preferito per l\'aggiornamento, disattiva l\'aggiornamento automatico per CamAPS FX nel Google Play Store. Se hai domande, contatta il servizio clienti di Ypsomed.',
	
	header_24:'Una nuova versione dell\'app 24/04/2024',
    body_24:'Caro utente di mylife CamAPS FX, Una nuova versione dell\'app sarà pronta nelle prossime 24 ore. Per scegliere il momento preferito per l\'aggiornamento, disattiva l\'aggiornamento automatico per CamAPS FX nel Google Play Store. Se hai domande, contatta il servizio clienti di Ypsomed.',
	
	header_23:'',
    body_23:'',
	
	header_22:'Aggiornamento del manuale utente 21/04/2024',
    body_22:'È disponibile una versione aggiornata del manuale d\'uso.',
	
	header_21:'',
    body_21:'',
	
	header_20:'',
    body_20:'',
	
	header_19:'',
    body_19:'',

    header_18:'',
    body_18:'',

    header_17:'',
    body_17:'',

	header_16:'Aggiornamento del manuale utente 04/03/2024',
    body_16:'È disponibile una versione aggiornata del manuale d\'uso.',
	
	header_15:'Aggiornamento del manuale utente 04/01/2024',
    body_15:'È disponibile una versione aggiornata del manuale d\'uso.',
	
	header_14:'Android OS 14 22/11/2023',
    body_14:'Promemoria: Per garantire un uso ininterrotto dell\'applicazione mylife CamAPS FX, prima di effettuare l\'aggiornamento a Android OS 14 controlla il sito web del produttore del sensore e verificane la compatibilità. Nota: Gli aggiornamenti automatici potrebbero essere attivati per impostazione predefinita nelle impostazioni del tuo telefono.',
	
	header_13:'AVVISO DI SICUREZZA 14/11/2023',
    body_13:'AVVISO DI SICUREZZA ',
    body_13_link_text:'Si prega di leggere dell\'importante avviso',
    body_13_end: '. Vai al menu dell\'app -> Notifiche di sicurezza.',
    body_13_link:'https://s3.eu-west-1.amazonaws.com/camaps.safety.notices/Field+Safety+Notice+ET5604+Mylife+Users+final-+IT.pdf',

	header_12:'Aggiornamento del manuale utente 10/11/2023',
    body_12:'È disponibile una versione aggiornata del manuale d\'uso.',
	
	header_11:'Android OS 14 12/09/2023',
    body_11:'Per garantire un uso ininterrotto dell\'applicazione mylife CamAPS FX, prima di effettuare l\'aggiornamento a Android OS 14 controlla il sito web del produttore del sensore e verificane la compatibilità. Nota: Gli aggiornamenti automatici potrebbero essere attivati per impostazione predefinita nelle impostazioni del tuo telefono.Assicurarsi di stare utilizzando l\'app mylife CamAPS FX versione 1.4(173) prima di aggiornare ad Android OS 14.',
	
	header_10:'AVVISO DI SICUREZZA 15/08/2023',
    body_10:'AVVISO DI SICUREZZA Si prega di leggere dell\'importante avviso. Vai al menu dell\'app -> Notifiche di sicurezza.',
	
	header_9:'Aggiornamento del manuale utente 27/07/2023',
    body_9:'È disponibile una versione aggiornata del manuale d\'uso.',
	
	header_8:'Compatibilità di Glooko 21/07/2023',
    body_8:'Come annunciato all\'inizio di quest\'anno, diasend sarà interrotto il 28 luglio. Se desideri continuare a condividere i dati con il tuo team diabetologico, aggiorna il tuo account Diasend a Glooko o crea un nuovo account Glooko. Aggiorna alla versione più recente di mylife CamAPS FX, inserisci il tuo nuovo nome utente e password Glooko nel menu di condivisione e riavvia il tuo smartphone. Diasend rimarrà disponibile per la visualizzazione dei dati storici per il resto dell\'anno.',
	
	header_7:'Samsung A-Series 17/07/2023',
    body_7:'Gli utenti di modelli Samsung A-Series (ad esempio A53, A33) potrebbero aver riscontrato una perdita di segnale al sensore CGM dopo l\'ultimo aggiornamento di Samsung One UI 5.1. Samsung ha identificato il problema e una soluzione è in fase di sviluppo. Samsung prevede di rilasciare un aggiornamento a One UI il 24 luglio (a seconda della regione), che risolverà il problema della perdita di segnale. Si consiglia di installare l\'aggiornamento quando sarà disponibile. Se non hai ancora aggiornato a One UI 5.1, ti consigliamo di attendere il prossimo aggiornamento di Samsung One UI.',

    header_6:'Compatibilità di Glooko 08/06/2023',
    body_6:'mylife CamAPS FX è compatibile con Glooko. Si prega di aggiornare l\'app mylife CamAPS FX alla versione 1.4(172). Se si condivide dati con una clinica, verrà ricevuta una notifica all\'interno del proprio account diasend esistente quando la clinica effettuerà l\'aggiornamento a Glooko. Una volta completato l\'aggiornamento, inserire il nuovo nome utente e la nuova password di Glooko nel menu di condivisione e riavviare il proprio smartphone. Si prega di effettuare l\'aggiornamento a Glooko entro il 28 luglio 2023. Diasend sarà disponibile per visualizzare i dati storici per il resto dell\'anno.',

    header_5:'',
    body_5:'',

    header_4:'Aggiornamento del manuale utente 27/05/2023',
    body_4:'È disponibile una versione aggiornata del manuale d\'uso.',

    header_3:'Avvisi via SMS  (Solo Ypsopump) 11/05/2023',
    body_3:'Con il prossimo aggiornamento 1.4(171) a metà maggio, sarà possibile attivare fino a 5 follower SMS E utilizzare contemporaneamente la modalità Companion. Per utilizzare la modalità Companion, il telefono dell\'utente deve avere una connessione internet per condividere i dati. Gli utenti devono avere una SIM card per consentire l\'invio di SMS dal loro telefono. Se si utilizza una di queste opzioni, si consiglia di verificare che il proprio piano mobile includa la copertura SMS e/o dati in base all\'opzione utilizzata.',

    header_2:'',
    body_2:'',

    header_1:'',
    body_1:'',
};
