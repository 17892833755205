/**
 * Texts for header
 */
export const headerText = {
    cookiesIntroText: 'Vi bruker informasjonskapsler til å spore besøk til nettstedet vårt. Vi lagrer ikke personopplysninger.',
    cookiesAccept: 'Godta informasjonskapsler',
    cookiesDeny: 'Avvis informasjonskapsler',
    tabFAQs: 'Vanlige spørsmål',
    tabSupport: 'Brukerstøtte',
    tabHistory: 'Historie',
    tabReviews: 'Anmeldelser',
    // tabTraining: 'Opplæring',
    // tabHcpTraining: '',
    tabTraining: 'Brukeropplæring',
    tabHcpTraining: 'Helseopplæring',
    tabOrders: '',

    tabNotifications: 'Varsler',
};
