/**
 * Texts for Training page
 */
export const trainingPageText = {
    title: 'Szkolenie — aplikacja CamAPS FX',
    titleMeta: 'Zapewniamy bezpłatne szkolenie online dla użytkowników aplikacji CamAPS FX',
    descriptionMeta: 'W ramach programu Cambridge Diabetes Education Programme odbywają się bezpłatne certyfikowane szkolenia online przeznaczone dla osób z cukrzycą typu 1, ich rodziców i opiekunów, personelu szkolnego i personelu medycznego.',
    keywords:
        'trening camaps, trening camaps fx,  ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'pl_pl',

    trainingHeader: 'Certyfikowane szkolenie',
    trainingHeader_2: 'Dla personelu medycznego',
    trainingHeader_3: 'Szkolenie użytkowników',
    trainingHeaderText: 'BEZPŁATNE szkolenie online dotyczące aplikacji CamAPS FX organizowane w ramach programu Cambridge Diabetes Education Programme (CDEP).',
    websiteLink: 'https://www.camdiabtraining.com/account-sign-in.html',
    websiteLinkHeading: 'Szkolenie CDEP',
    getTrained: 'Weź udział w szkoleniu',

    trainingHeaderIat: "Szkolenie CamAPS FX",
    trainingHeaderTextIat: "Ukończ podstawowe szkolenie w aplikacji przed użyciem aplikacji CamAPS FX.",
    trainingSubHeaderOneIat: "Pierwsze kroki",
    trainingSubTextOneIat: "Pobierz aplikację CamAPS FX. <br>Załóż konto, aby rozpocząć szkolenie.",
    trainingSubHeaderTwoIat: "Potrzebujesz pomocy przy instalacji aplikacji?",
    trainingParaTwoStartIat: "Odwiedź naszą",
    trainingParaTwoEndIat: "sekcję z poradami.",
    websiteLinkHeadingSupportIat: "Wsparcie",
    trainingSubHeaderThreeIat: "Dodatkowe zasoby",
    animationsYpsoPumpText: "Animacje",
    animationsAndText: "i",
    animationsDanaText: "Animacje (pompa Dana)",
    animationsFurtherText: "Ucz się dzięki wizualizacjom krok po kroku.",
    webinarsText: "Webinaria",
    webinarsFurtherText: "Obejrzyj sesje prowadzone przez ekspertów o korzystaniu z aplikacji.",
    websiteAnimationsYpsoPumpLink: "https://www.camdiabtraining.com/view/27/mylife-YpsoPump-users.htm",
    websiteAnimationsDanaLink: "https://www.camdiabtraining.com/view/25/Dana-insulin-pump-users.htm",
    websiteWebinarsLink: "https://www.camdiabtraining.com/view/20/Webinars.htm"

};
