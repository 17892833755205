/**
 * Texts for Training page
 */
export const trainingPageText = {
    title: 'Schulung - CamAPS FX',
    titleMeta: 'Wir bieten kostenlose Online-Schulungen für CamAPS FX-Benutzer',
    descriptionMeta: 'Das Diabetes-Ausbildungsprogramm von Cambridge bietet eine kostenlose ' +
        'zertifizierte Online-Schulung für Menschen mit Typ-1-Diabetes, Eltern und Erziehungsberechtigte, Schulpersonal und medizinisches Fachpersonal.',
    keywords:
        'camaps schulung, camaps fx training,  camaps training, camaps fx schulung,  ' +
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ' +
        '',
    localeMeta: 'de_de',

    trainingHeader: 'Zertifiziertes Training',
    trainingHeader_2: 'Für medizinisches Fachpersonal',
    trainingHeader_3: 'Benutzerschulung',
    trainingHeaderText: 'KOSTENLOSES online CamAPS FX Training des Cambridge Diabetes Education Programme (CDEP).',
    websiteLink: 'https://de.camdiabtraining.com',
    websiteLinkHeading: 'CDEP Training',
    getTrained: 'Zum Training',

    trainingHeaderIat: "CamAPS FX Training",
    trainingHeaderTextIat: "Schließen Sie das wesentliche In-App-Training ab, bevor Sie die CamAPS FX-App verwenden.",
    trainingSubHeaderOneIat: "Erste Schritte",
    trainingSubTextOneIat: "Laden Sie die CamAPS FX-App herunter. <br>Erstellen Sie ein Konto, um mit dem Training zu beginnen.",
    trainingSubHeaderTwoIat: "Brauchen Sie Hilfe bei der Installation der App?",
    trainingParaTwoStartIat: "Besuchen Sie unseren",
    trainingParaTwoEndIat: "Bereich für Anleitungen.",
    websiteLinkHeadingSupportIat: "Support",
    trainingSubHeaderThreeIat: "Weitere Ressourcen",
    animationsYpsoPumpText: "Animationen",
    animationsAndText: "und",
    animationsDanaText: "Animationen (Dana-Pumpe)",
    animationsFurtherText: "Lernen Sie anhand von Schritt-für-Schritt-Visualisierungen.",
    webinarsText: "Webinare",
    webinarsFurtherText: "Sehen Sie sich von Experten geleitete Sitzungen zur Nutzung der App an.",
    websiteAnimationsYpsoPumpLink: "https://www.camdiabtraining.com/view/27/mylife-YpsoPump-users.htm",
    websiteAnimationsDanaLink: "https://www.camdiabtraining.com/view/25/Dana-insulin-pump-users.htm",
    websiteWebinarsLink: "https://www.camdiabtraining.com/view/20/Webinars.htm"

};

